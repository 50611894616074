const ChevronDownFooter = ({ className = '', ...props }) => {
  return (
    <svg
      width={12}
      height={7}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.864.136a.462.462 0 010 .653L6.327 6.327a.461.461 0 01-.654 0L.135.789A.462.462 0 11.79.136l5.21 5.212L11.212.136a.461.461 0 01.653 0z"
        fill="black"
      />
    </svg>
  )
}

export default ChevronDownFooter
