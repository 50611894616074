import cn from 'classnames'
import { FC, useState } from 'react'
// import Link from '@components/ui/Link'
import client, { customerAccessTokenVar } from '@commerce/client'
import PaymentMethods from '@components/common/payment-methods'
import { ChevronDownFooter, ChevronUpFooter } from '@components/icons'
import { SocialIcons } from '@components/ui'
import { FooterQuickLinks, Footer as FooterType, SocialAndPayments } from '@lib/types/Navigation'
import { SubscribeForm } from 'components/common'
import Cookies from 'js-cookie'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import QuickLinks from './QuickLinks'

interface Props {
  className?: string
  children?: any
  footer?: FooterType
  footerQuickLinks?: FooterQuickLinks
  socialAndPayments?: SocialAndPayments
  countryFlags?: {
    [key: string]: any
  }[]
}

const Footer: FC<Props> = ({ footer, footerQuickLinks, socialAndPayments, countryFlags }) => {
  const [expandedIndex, setExpandedIndex] = useState(-1)
  const paymentTypes = socialAndPayments?.body?.find((slice) => slice.slice_type === 'payment_methods')?.items
  const socialData = {
    facebook: {
      icon: socialAndPayments?.facebook_icon,
      link: socialAndPayments?.facebook_link,
    },
    instagram: {
      icon: socialAndPayments?.instagram_icon,
      link: socialAndPayments?.instagram_link,
    },
    youtube: {
      icon: socialAndPayments?.youtube_icon,
      link: socialAndPayments?.youtube_link,
    },
    pinterest: {
      icon: socialAndPayments?.pinterest_icon,
      link: socialAndPayments?.pinterest_link,
    },
  }

  const { locale, asPath: currentPath, push } = useRouter()

  const changeCountry = (e: React.MouseEvent<HTMLElement>, selectedCountry: string) => {
    e.preventDefault()
    if (locale !== selectedCountry) {
      Cookies.set('NEXT_LOCALE', selectedCountry)
      customerAccessTokenVar('')
      client.clearStore()
      push(currentPath, currentPath, { locale: selectedCountry })
    }
  }

  const currentCountry = countryFlags?.find((country) => country.locale === locale)

  return (
    <footer>
      <div className="bg-lightBg">
        <div className="wrapper pt-8 pb-24 laptop:py-32">
          <div className="grid grid-cols-1 laptop:grid-cols-6 laptop:space-x-8 desktop:space-x-16 space-y-16 laptop:space-y-0 place-content-center">
            {footer &&
              footer?.body?.map((column, index) => {
                const setExpanded = () => {
                  if (expandedIndex === index) {
                    setExpandedIndex(-1)
                  } else {
                    setExpandedIndex(index)
                  }
                }
                return (
                  <div
                    key={column?.primary?.heading}
                    className={cn({
                      'laptop:col-span-2 laptop:pl-50 order-first laptop:order-none': index === footer.body.length - 1,
                      //'border-b border-dark laptop:border-none': index > 0,
                    })}
                  >
                    <div className="hidden laptop:block">
                      <div className="title-small text-black font-bold uppercase pb-8 border-b border-white mb-8">
                        {column?.primary?.heading}
                      </div>
                    </div>
                    <div
                      className={cn('laptop:hidden w-full pb-12 focus:outline-none flex justify-between', {
                        'border-b border-white': expandedIndex !== index,
                      })}
                      onClick={setExpanded}
                    >
                      <div className="title-small text-black font-bold uppercase ">{column?.primary?.heading}</div>
                      {index !== footer?.body?.length - 1 && expandedIndex !== index && (
                        <ChevronDownFooter className="mt-4" fill="black" />
                      )}
                      {index !== footer?.body?.length - 1 && expandedIndex === index && (
                        <ChevronUpFooter className="mt-4" />
                      )}
                    </div>

                    <div
                      className={cn('pb-16 laptop:pb-0', {
                        'hidden laptop:block': index !== footer?.body?.length - 1 && expandedIndex !== index,
                        'border-b border-white laptop:border-none':
                          expandedIndex === index && index !== footer?.body?.length - 1,
                      })}
                    >
                      {column?.primary?.email && (
                        <div className="mb-10 body-link-small text-black ">
                          <Link href={'mailto:' + column.primary.email} legacyBehavior>
                            {column.primary.email}
                          </Link>
                        </div>
                      )}
                      {column?.primary?.phone && (
                        <div className="mb-10 body laptop:body-small text-black">
                          <Link href={'tel:' + column.primary.phone} legacyBehavior>
                            {column.primary.phone}
                          </Link>
                        </div>
                      )}
                      <p className="body-small text-black">{column.primary?.description?.[0]?.text}</p>
                      {index === footer?.body?.length - 1 && footer?.klaviyo_list_id && footer?.klaviyo_list_id !== '' && (
                        <div className="mt-20 mb-8 laptop:mb-0 max-w-394">
                          <SubscribeForm listID={footer?.klaviyo_list_id} listSource="footer" />
                        </div>
                      )}
                      {column?.items && (
                        <ul className="laptop:mt-15 space-y-10">
                          {column.items.map((item) => (
                            <li key={item?.link_title} className="body text-black">
                              {item?.link && <Link href={item.link}>{item.link_title}</Link>}
                            </li>
                          ))}
                        </ul>
                      )}
                      {index === footer?.body?.length - 1 && socialData && (
                        <div className="hidden laptop:block mt-20">
                          <SocialIcons data={socialData} />
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="mt-32 laptop:mt-50 flex flex-col gap-y-32 laptop:flex-row items-center justify-between">
            <div className="flex gap-10 order-2">
              <div className="flex gap-7">
                {currentCountry && (
                  <span className="currentCountry flex items-center">
                    <span className="flex items-center mr-7">You are visiting</span>
                    <Image
                      src={currentCountry?.flag?.url}
                      width={currentCountry?.flag?.dimensions?.width}
                      height={currentCountry?.flag?.dimensions?.height}
                      alt=""
                    />
                  </span>
                )}
                {countryFlags &&
                  countryFlags.map((country, i) => {
                    if (country !== currentCountry) {
                      return (
                        <Link href={`${currentPath}`} locale={country.locale} key={i} passHref legacyBehavior>
                          <a
                            aria-label={`${country.locale}`}
                            className="cursor-pointer flex items-center"
                            onClick={(e) => changeCountry(e, country.locale)}
                          >
                            <Image
                              src={country.flag.url}
                              width={country.flag.dimensions.width}
                              height={country.flag.dimensions.height}
                              alt=""
                            />
                          </a>
                        </Link>
                      )
                    }
                  })}
              </div>
              <div className="body-small text-charcoal order-last hidden laptop:block">{footer?.copyright_text}</div>
            </div>
            <div className="body-small text-charcoal order-last block laptop:hidden laptop:order-first">
              {footer?.copyright_text}
            </div>

            {socialData && (
              <div className="laptop:hidden">
                <SocialIcons data={socialData} />
              </div>
            )}
            {paymentTypes && paymentTypes.length > 0 && (
              <div className="order-3">
                <PaymentMethods paymentMethods={paymentTypes} />
              </div>
            )}
          </div>
        </div>
      </div>
      <QuickLinks
        title={footerQuickLinks?.quick_links_title}
        columns={footerQuickLinks?.body}
        bgColour={footerQuickLinks?.quick_links_background_colour}
        textColour={footerQuickLinks?.quick_links_text_colour}
        showQuickLinks={footerQuickLinks?.show_quick_links}
      />
      <style jsx>{`
        .currentCountry::after {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          margin-left: 6px;
          background-color: #afb1b4;
        }
      `}</style>
    </footer>
  )
}

export default Footer
