import { PrismicImage } from '@lib/prismic'
import { FC } from 'react'
import Image from "next/legacy/image"

interface props {
  paymentMethods: {
    payment_method: PrismicImage
  }[]
}

const PaymentMethods: FC<props> = ({ paymentMethods }) => {
  const defaultAlt = 'Payment Method'
  if (!paymentMethods.length) return <></>
  return (
    <div className="flex gap-7">
      {paymentMethods.map((method, index) => {
        if (method.payment_method?.url) {
          return (
            <Image
              key={`${method.payment_method.alt}-${index}`}
              src={method.payment_method.url}
              alt={method.payment_method?.alt || defaultAlt}
              width={38}
              height={23}
            />
          )
        }
      })}
    </div>
  )
}

export default PaymentMethods
