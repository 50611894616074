import cn from 'classnames'
import Link from 'next/link'
type QuickLink = { label: string; link: string }

interface Props {
  title?: string | null
  columns?: { items: QuickLink[] }[]
  bgColour?: string
  textColour?: string
  showQuickLinks?: boolean
}

{
  /* */
}
const Column = ({ items, order }: { items: QuickLink[]; order: number }) => {
  return (
    <ul
      className={cn('tablet:border-t border-white  tablet:pt-18 w-1/2 tablet:w-full border-opacity-50', {
        'order-0': order === 0,
        'order-2 tablet:order-1': order === 1,
        'order-4 tablet:order-2': order === 2,
        'order-1 tablet:order-3': order === 3,
        'order-3 tablet:order-4': order === 4,
        'order-5 tablet:order-5': order === 5,
      })}
    >
      {items.map((quickLink, index) => (
        <li key={`quick-link-${index}`} className="body-small pb-8">
          <Link href={quickLink?.link || '/'} passHref>
            {quickLink.label}
          </Link>
        </li>
      ))}
    </ul>
  )
}

const QuickLinks = ({ title, columns, bgColour, textColour, showQuickLinks = true }: Props) => {
  if (!columns || columns.length === 0 || !showQuickLinks) return null

  return (
    <div style={{ backgroundColor: bgColour }}>
      <div className="wrapper pt-28 px-32 pb-32" style={{ color: textColour }}>
        {title && (
          <div className="title-small pb-8 mb-8 border-b border-white tablet:mb-0 tablet:border-0 border-opacity-50">
            {title}
          </div>
        )}

        <div className="flex flex-wrap tablet:flex-nowrap tablet:gap-18">
          {columns.map((c, index) => {
            // Do not render if no items or number of columns is more then 6
            if (!c?.items || c.items.length === 0 || index > 5) return null
            return <Column items={c.items} key={`footer-quick-links-${index}`} order={index} />
          })}
        </div>
      </div>
    </div>
  )
}

export default QuickLinks
