const ChevronUpFooter = ({ className = '', ...props }) => {
  return (
    <svg
      width={12}
      height={7}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.136 6.327a.461.461 0 010-.654L5.673.136a.461.461 0 01.654 0l5.538 5.537a.462.462 0 01-.654.654l-5.21-5.212L.788 6.327a.461.461 0 01-.653 0z"
        fill="black"
      />
    </svg>
  )
}

export default ChevronUpFooter
