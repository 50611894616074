import { SocialIconsData } from '@lib/types/SocialIcons'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { FC } from 'react'

interface props {
  data?: SocialIconsData
}

const SocialIcons: FC<props> = ({ data }) => {
  if (!data) return <></>
  const { facebook, instagram, youtube, pinterest, width, height } = data
  return (
    <div className="flex gap-10">
      {instagram?.icon?.url && (
        <Link href={instagram?.link || '/'} target="_blank" rel="noreferrer" passHref>
          <Image
            className="rounded-full"
            src={instagram.icon.url}
            alt={instagram.icon.alt || 'Facebook'}
            width={width ? Number(width) : 24}
            height={height ? Number(height) : 24}
          />
        </Link>
      )}
      {facebook?.icon?.url && (
        <Link href={facebook?.link || '/'} target="_blank" rel="noreferrer" passHref>
          <Image
            className="rounded-full"
            src={facebook.icon.url}
            alt={facebook.icon.alt || 'Facebook'}
            width={width ? Number(width) : 24}
            height={height ? Number(height) : 24}
          />
        </Link>
      )}

      {pinterest?.icon?.url && (
        <Link href={pinterest?.link || '/'} target="_blank" rel="noreferrer" passHref>
          <Image
            className="rounded-full"
            src={pinterest.icon.url}
            alt={pinterest.icon.alt || 'Facebook'}
            width={width ? Number(width) : 24}
            height={height ? Number(height) : 24}
          />
        </Link>
      )}
      {youtube?.icon?.url && (
        <Link href={youtube?.link || '/'} target="_blank" rel="noreferrer" passHref>
          <Image
            className="rounded-full"
            src={youtube.icon.url}
            alt={youtube.icon.alt || 'Facebook'}
            width={width ? Number(width) : 24}
            height={height ? Number(height) : 24}
          />
        </Link>
      )}
    </div>
  )
}

export default SocialIcons
